import { getValueFromDto as getValue, getArrayFromDto as getArray } from './_helpers.js';

export default class CustomerRentalMapLocation {
	constructor(dto) {
		this.customerLocationId = getValue(dto, 'customerLocationId', 'number', null);
		this.latitude = getValue(dto, 'latitude', 'number', null);
		this.longitude = getValue(dto, 'longitude', 'number', null);
		this.workOrderCount = getValue(dto, 'workOrderCount', 'number', null);
		this.rentalItemTypes = getArray(dto, 'rentalItemTypes', []);
	}
}
