import { addCompanyIdToUrl, fetchWrap, offlineResponse } from '../_helpers';

export default {
	getWasteDisposalsUrl() { return addCompanyIdToUrl('/api/Reports/WasteDisposals').toString(); },
	getWasteDisposalsByLandSiteUrl() { return addCompanyIdToUrl('/api/Reports/WasteDisposalsByLandSite').toString() },
	getWasteCollectionsDisposalsUrl() { return addCompanyIdToUrl('/api/Reports/WasteCollectionsDisposals').toString(); },
	getWasteCollectionsUrl() { return addCompanyIdToUrl('/api/Reports/WasteCollections').toString(); },
	getVehicleMaintenanceHistoryUrl() { return addCompanyIdToUrl('/api/Reports/VehicleMaintenanceHistory').toString(); },
	getPaymentsUrl() { return addCompanyIdToUrl('/api/Reports/Payments').toString(); },
	getInvoiceItemReportUrl() { return addCompanyIdToUrl('/api/Reports/InvoiceItemReport').toString(); },
	getTimesheetReportUrl() { return addCompanyIdToUrl('/api/Reports/TimesheetReport').toString(); },
	getTimesheetByUserReportUrl() { return addCompanyIdToUrl('/api/Reports/TimesheetByUserReport').toString(); },
	async getCustomerCountiesByState() {
		let response;
		try {
			response = await fetchWrap('/api/Reports/CustomerCountiesByState');
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return await response.json();
		} else {
			throw response;
		}
	},
};
