import { getValueFromDto as getValue } from './_helpers.js';

export default class Service {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.name = getValue(dto, 'name', 'string', '');
		this.description = getValue(dto, 'description', 'string', '');
		this.price = getValue(dto, 'price', 'number', 0);
		// this.taxable = getValue(dto, 'taxable', 'boolean', false);
		this.wasteTypeId = getValue(dto, 'wasteTypeId', 'number', null);
		Object.defineProperty(this, 'wasteType', { enumerable: false, value: null, writable: true });
		this.requireInspection = getValue(dto, 'requireInspection', 'boolean', false);
		this.active = getValue(dto, 'active', 'boolean', true);
	}
}
